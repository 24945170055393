import {
  adminPostPaymentCompleteBackToParentRoute,
  connectDevicesRoute,
  shopRequirementsRoute,
} from '../../common/constants/routes'

export const pathsWithoutHeader = new Set([
  connectDevicesRoute,
  shopRequirementsRoute,
  adminPostPaymentCompleteBackToParentRoute,
])
export const patternsWithoutHeader = [] as RegExp[]
