import * as React from 'react'
import { useDevTools } from '../../../../hooks/useDevTools'
import { isLocalDev } from '../../../../env/utils/isLocalDev'

export function useSwitchToAirplaneModeOnNetworkLoss() {
  const [, setDevTools] = useDevTools()

  React.useEffect(() => {
    const offlineListener = () => {
      if (isLocalDev) {
        setDevTools((s) => ({ ...s, networkOffline: true, airplaneMode: true, writeQueryResponseToDisk: false }))
      }
    }
    window.addEventListener('offline', offlineListener)
    return () => {
      window.removeEventListener('offline', offlineListener)
    }
  }, [setDevTools])
}
