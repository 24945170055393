import { RedirectLoginOptions } from '@auth0/auth0-react'
import { atom, useRecoilState } from 'recoil'
import { enum_membership_tier_enum } from '../../generated/graphql'

export type NetworkBridgingLogInAppState =
  | {
      // !!don't use this unless you have whitelisted this value in auth0 dashboard. only here to match typescript for auth0 appState. use postAuthRedirectUri.
      returnTo?: string

      postAuthRedirectUri?: string
      realize_login_request_id?: string
      referralSource?: string | null
      login_initiated_as_sign_up?: boolean
    }
  | undefined

type LogInState = {
  onRedirectCallbackCalled: boolean
  postAuthRedirectInProgress: boolean
  isLoggingIn: boolean
  loginOptions?: Omit<RedirectLoginOptions, 'appState'> & {
    appState?: NetworkBridgingLogInAppState
    realize_signup_type?: enum_membership_tier_enum
    screen_hint?: 'signup'
    realize_form?: 'ulf-signup'
    compound_franchise_location_id?: `${'CA' | 'US'}|${string}`
  }
}

const defaultLogInState: LogInState = {
  isLoggingIn: false,
  loginOptions: { appState: undefined },
  postAuthRedirectInProgress: false,
  onRedirectCallbackCalled: false,
}

const isLoggingInState = atom({
  key: 'logInState',
  default: defaultLogInState,
})

export function useLogInState() {
  return useRecoilState(isLoggingInState)
}
