'use client'

import { usePathname } from 'next/navigation'
import { dynamicSignatureRoutes } from '../common/constants/routes'
import React from 'react'

// TODO getting the dynamicSignatureRoutes should be a build step where we pull the data from the directory structure and make sure we have all of the
// dynamic routes accounted for
export function useRoutePattern() {
  const pathname = usePathname()
  const routePattern = React.useMemo(() => {
    if (!pathname) return null
    return dynamicSignatureRoutes.find((dynamicSignature) => {
      const dynamicSignatureSegments = dynamicSignature.split('/')
      const pathnameSegments = pathname.split('/')
      if (dynamicSignatureSegments.length !== pathnameSegments.length) return false

      return dynamicSignatureSegments.every((segment, index) => {
        if (segment.startsWith('[') && segment.endsWith(']')) {
          return true
        }
        return segment === pathnameSegments[index]
      })
    })
  }, [pathname])
  return routePattern ?? pathname
}
