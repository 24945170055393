'use client'
import React from 'react'
import { useElement } from './useElement'
import type { UseElementInput } from './useElement'
import { MutationObserverOptions, useMutationObservable } from './useMutationObserver'
import debounce from 'lodash/debounce'

const initialState = {
  width: 0,
  height: 0,
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  x: 0,
  y: 0,
  toJSON: () => '',
}

const useResizeObserverReady = () => {
  const [resizeObserverReady, setResizeObserverReady] = React.useState(
    !!(typeof window !== 'undefined' && window.ResizeObserver)
  )

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !window.ResizeObserver) {
      ;(async () => {
        const ResizeObserverPolyfill = (await import('resize-observer-polyfill')).default
        window.ResizeObserver = ResizeObserverPolyfill
        if (window.ResizeObserver) setResizeObserverReady(true)
      })()
    }
  }, [])

  return resizeObserverReady
}

type Options = { debounceTime?: number; debug?: boolean; mutationObserverOptions?: MutationObserverOptions }

/**
 * get the size of any element and observe it so we update if it changes size at any point
 * @returns the element's width and height in pixels
 *  ref is the reference to the element whose height and width is required
 * @example
 *  const ref = useRef(null);
 *  const { height, width } = useDimension(ref)
 *  <div ref={ref} />
 * @example
 *  const node = document.getElementById('some-id')
 *  const { width, height } = useDimensions(node)
 *  <div id="some-id" />
 * @example
 *  const { width, height } = useDimensions('#some-id')
 *  <div id="some-id" />
 */
export function useDimensions<T extends HTMLElement = HTMLElement>(
  input: UseElementInput,
  { debounceTime, debug, mutationObserverOptions }: Options = {}
) {
  const [dimensions, setDimensions] = React.useState<DOMRect>(initialState)
  const resizeObserverReady = useResizeObserverReady()
  const resizeObserverRef = React.useRef<null | ResizeObserver>(null)
  const elt = useElement<T>(input)
  useMutationObservable<T>(
    elt,
    (entries) => {
      entries.forEach((_mutationRecord) => {
        if (elt instanceof HTMLElement) {
          const nextDimensions = elt.getBoundingClientRect()
          if (JSON.stringify(nextDimensions) !== JSON.stringify(dimensions)) {
            // eslint-disable-next-line no-debugger
            if (debug) debugger

            setDimensions(nextDimensions)
          }
        }
      })
    },
    { debounceTime, ...mutationObserverOptions }
  )

  React.useEffect(() => {
    let unmounted = false
    if (!resizeObserverReady) return

    function resizeCallback(entries: ResizeObserverEntry[] = []) {
      entries.forEach((entry) => {
        if (entry.target instanceof HTMLElement) {
          const entryDimensions = entry.target.getBoundingClientRect()
          if (!unmounted) {
            // eslint-disable-next-line no-debugger
            if (debug) debugger
            setDimensions(entryDimensions)
          }
        }
      })
    }

    const optionallyDebounced = debounceTime ? debounce(resizeCallback, debounceTime) : resizeCallback
    resizeObserverRef.current = new window.ResizeObserver(optionallyDebounced)
    if (elt) resizeObserverRef.current.observe(elt)

    return () => {
      unmounted = true
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect()
      }
    }
  }, [elt, resizeObserverReady, input, debounceTime, debug])
  return dimensions
}
