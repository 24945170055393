import * as React from 'react'
import { createPortal } from 'react-dom'
import { useElement } from '../../hooks/useElement'
import { useIsomorphicIsMounted } from './EnvironmentSpecificSsrComponent'

export interface PortalProps {
  /**
   * The destination element/selector for children of `Portal`..
   */
  to: HTMLElement | string | React.MutableRefObject<HTMLElement>
}

/**
 * Insert children of `Portal` into the element given as `destination`. If
 * `destination` is not defined render as `null`.
 */
export function Portal({ to, children }: React.PropsWithChildren<PortalProps>) {
  const isMounted = useIsomorphicIsMounted()
  const destinationElement = useElement(to)

  if (destinationElement && isMounted) {
    return createPortal(children, destinationElement)
  } else {
    return null
  }
}
