'use client'
import * as React from 'react'

import { useElement, UseElementInput } from './useElement'
import debounce from 'lodash/debounce'
import { useAutoUpdatingRef } from './useAutoUpdatingRef'

const defaultOptions = {
  config: { attributes: true, childList: true, subtree: true, characterData: true },
  debounceTime: 0,
}
export type MutationObserverOptions = Partial<typeof defaultOptions>

// TODO should add some logic to handle a callback when an observed elt unmounts
export function useMutationObservable<T extends HTMLElement = HTMLElement>(
  targetEl: UseElementInput,
  callback: MutationCallback,
  options?: MutationObserverOptions
) {
  const memoizedOptions = React.useMemo(() => {
    return {
      config: {
        attributes: options?.config?.attributes ?? defaultOptions.config.attributes,
        childList: options?.config?.childList ?? defaultOptions.config.childList,
        subtree: options?.config?.subtree ?? defaultOptions.config.subtree,
        characterData: options?.config?.characterData ?? defaultOptions.config.characterData,
      },
      debounceTime: options?.debounceTime ?? defaultOptions.debounceTime,
    }
  }, [
    options?.config?.attributes,
    options?.config?.childList,
    options?.config?.subtree,
    options?.config?.characterData,
    options?.debounceTime,
  ])

  const elt = useElement<T>(targetEl)

  const [observer, setObserver] = React.useState<null | MutationObserver>(null)

  const callbackRef = useAutoUpdatingRef(callback)

  React.useEffect(() => {
    const cb = callbackRef.current
    if (!cb || typeof cb !== 'function') {
      console.warn(`You must provide a valida callback function, instead you've provided ${cb}`)
      return
    }
    const { debounceTime } = memoizedOptions
    const obs = new MutationObserver(debounceTime > 0 ? debounce(cb, debounceTime) : cb)

    setObserver(obs)
  }, [callbackRef, memoizedOptions, setObserver, elt])

  React.useEffect(() => {
    if (!observer) return
    if (!elt) return
    const { config } = memoizedOptions
    try {
      observer.observe(elt, config)
    } catch (e) {
      console.error(e)
    }
    return () => {
      observer?.disconnect()
    }
  }, [observer, elt, memoizedOptions])
}
