import * as React from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { useIsCanada } from '../../account/features/franchise-membership/hooks/useHost/useIsCanada'
import { shopLabsRoute, shopRoute } from '../../../common/constants/routes'

const labsPattern = new RegExp(`^${shopLabsRoute}`)

export function useRedirectCanadianUsersAwayFromLabPages() {
  const { replace } = useRouter()
  const pathname = usePathname()
  const isCanada = useIsCanada()

  React.useEffect(() => {
    if (pathname && labsPattern.test(pathname) && isCanada) {
      replace(shopRoute)
    }
  }, [isCanada, pathname, replace])
}
