import * as React from 'react'
import { useDevice } from '../../../hooks/useDevice'
import { useScrollingDirection } from '../../../hooks/useScrollingDirection'

export function useHideHeaderOnScroll() {
  const getIsHideable = (mainHeaderHeight: number) =>
    typeof window !== 'undefined' && window.scrollY > mainHeaderHeight * 2

  const { isTabletDown } = useDevice()

  const mainHeaderHeight = isTabletDown ? 50 : 58

  const [hideHeader, setHideHeader] = React.useState(false)
  const { down, up } = useScrollingDirection()

  React.useEffect(() => {
    const listener = () => {
      if (down && getIsHideable(mainHeaderHeight) && !hideHeader) {
        setHideHeader(true)
      } else if ((up || !getIsHideable(mainHeaderHeight)) && hideHeader) {
        const isCloseToBottom = window.scrollY + window.innerHeight + 10 > document.body.scrollHeight
        // the bounce after overscrolling back to the normal screen when flicking/scrolling down to the bottom of the
        // page triggers an up scroll. if this is the case, we are at the bottom, not actually scrolling up so bail. the
        // + 10 is a small offset as this seems to register as false after the bottom bounce without it

        if (isCloseToBottom) return
        setHideHeader(false)
      }
    }
    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  }, [mainHeaderHeight, down, up, hideHeader])
  return { hideHeader, setHideHeader }
}
