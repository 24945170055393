'use client'
import * as React from 'react'
import { getPublicEnv } from '../../../../../../env/getEnvs'
import { homeRoute } from '../../../../../../common/constants/routes'
import { usePathname } from 'next/navigation'
// eslint-disable-next-line no-restricted-imports
import { franchiseRouteWhenFranchiseDomainNonexistent } from '../../../../../../common/constants/franchiseRouteWhenFranchiseDomainNonexistent'
import { isLocalDev } from '../../../../../../env/utils/isLocalDev'
import { useHost } from './useHost'
import { useIsCanada } from './useIsCanada'

export enum domains {
  axo = 'axo',
  franchise = 'franchise',
}

const {
  NEXT_PUBLIC_FRANCHISE_BOOKING_DOMAIN: franchiseDomain,
  NEXT_PUBLIC_ORIGIN_SERVER_URL: originDomain,
  NEXT_PUBLIC_CANADIAN_FRANCHISE_BOOKING_DOMAIN: canadianFranchiseBookingDomain,
  NEXT_PUBLIC_CANADIAN_APP_DOMAIN: canadianAppDomain,
} = getPublicEnv()

//deployed
//  on origin domain:
//     home: /
//     franchise home: booking.com
//     axo ui
//
// on franchise:
//     home: axo.com
//     franchise home: /
//     franchise ui
//
// on local
//     axo home: /
//     franchise home: /franchise
//     path===franchise ? franchise ui : axo ui
//
//

export const useFranchiseDeploymentData = () => {
  const host = useHost()
  const pathname = usePathname()

  const isCanada = useIsCanada()

  const isFranchiseDomain = [franchiseDomain, canadianFranchiseBookingDomain].some((x) => host?.includes(x))
  const isFranchiseRoute = !!pathname?.includes(franchiseRouteWhenFranchiseDomainNonexistent)
  const isDeployedOriginDomain = [originDomain, canadianAppDomain].some((x) => host?.includes(x))

  const [isFranchiseUi, setIsFranchiseUi] = React.useState(isFranchiseDomain || isFranchiseRoute)

  React.useEffect(() => {
    setIsFranchiseUi(isFranchiseDomain || isFranchiseRoute)
  }, [isFranchiseDomain, isFranchiseRoute])

  const axoHomeRoute = isLocalDev || isDeployedOriginDomain ? homeRoute : isCanada ? canadianAppDomain : originDomain

  const franchiseHomeRoute = isFranchiseDomain
    ? homeRoute
    : isLocalDev
      ? franchiseRouteWhenFranchiseDomainNonexistent
      : isDeployedOriginDomain
        ? isCanada
          ? canadianFranchiseBookingDomain
          : franchiseDomain
        : homeRoute
  return React.useMemo(
    () => ({ isFranchiseUi, axoHomeRoute, franchiseHomeRoute }),
    [isFranchiseUi, axoHomeRoute, franchiseHomeRoute]
  )
}
