import * as React from 'react'
import { atom, useRecoilState } from 'recoil'

// TODO at some point this should probably be refactored/renamed to navbar instead of announcement banner, and have
// announcement banner state fall under the umbrella of the navbar since we are now adding nav related state and actions
// to this recoil atom
type JsxFunction = (p?: any) => JSX.Element
type Options = Partial<{
  hideHeaderOnScroll: boolean
}>
type AnnouncementBannerState = { component: JsxFunction | null } & Options

const initialState: AnnouncementBannerState = {
  component: null,
  hideHeaderOnScroll: true,
}

const announcementBannerState = atom({
  key: 'announcementBanner',
  default: initialState,
})

type SetAnnouncementBannerInput = JsxFunction | JSX.Element | undefined

export function useSetAnnouncementBanner() {
  const [recoilState, setRecoilState] = useRecoilState(announcementBannerState)

  const setAnnouncementBanner = React.useCallback(
    (input?: SetAnnouncementBannerInput | undefined, options?: Options) => {
      const component = typeof input == 'function' ? input : typeof input === 'object' ? () => input : null
      return setRecoilState((s) => ({
        ...s,
        ...options,
        component,
      }))
    },
    [setRecoilState]
  )

  return {
    setAnnouncementBanner,
    AnnouncementBanner: recoilState.component,
    recoil: [recoilState, setRecoilState] as const,
  }
}

export const useAnnouncementBannerState = () => {
  const [recoilState, setRecoilState] = useRecoilState(announcementBannerState)
  return { ...recoilState, setRecoilState }
}
