import { enum_membership_tier_enum } from '../../../generated/graphql'

export const DEFAULT_MEMBERSHIP_TIER = enum_membership_tier_enum.FREE_MEMBER

/**
 * @deprecated
 */
export const DEPRECATED_LABS_ONLY_TIER = enum_membership_tier_enum.LABS_ONLY
export const FREE_MEMBER_TIERS = [
  // deprecated, migrate to ACTION_PLAN_ONLY ASAP
  enum_membership_tier_enum.LABS_ONLY,
  // deprecated, migrate to FREE_MEMBER before launch
  enum_membership_tier_enum.ACTION_PLAN_ONLY,
  enum_membership_tier_enum.FREE_MEMBER,
]
export const PAID_MEMBERSHIP_TIERS = [
  // deprecated, will migrate on launch
  enum_membership_tier_enum.MEMBER,
  enum_membership_tier_enum.PAID_MEMBER,
]
export const EMPLOYEE_MEMBERSHIP_TIER = enum_membership_tier_enum.EMPLOYEE

export const isActionPlanOnlyUserTier = (membershipTier?: enum_membership_tier_enum | null) =>
  membershipTier ? FREE_MEMBER_TIERS.includes(membershipTier) : false

export const isLimitedAccessMembershipTier = (membershipTier?: enum_membership_tier_enum | null) =>
  membershipTier ? FREE_MEMBER_TIERS.includes(membershipTier) : false
