import dynamic from 'next/dynamic'
import { dashboardPreviewRoute } from '../../../common/constants/routes'
import { usePathname } from 'next/navigation'

// TODO: switch all of this logic to use useSetAnnouncementBanner

const LazyDashboardPreviewBanner = dynamic(() =>
  import('../../metrics-explorer/atoms/DashboardPreviewBanner').then((x) => x.DashboardPreviewBanner)
)

export function useAnnouncementBanner_LEGACY() {
  const pathname = usePathname()

  return pathname === dashboardPreviewRoute ? LazyDashboardPreviewBanner : null
}
