import React from 'react'
import { Role } from '../../../common/constants/roles'
import {
  actionPlanCirclesPageDynamicSignature,
  actionPlanCirclesRoute,
  actionPlanReportsRoute,
  actionPlanRoute,
  dashboardsRoute,
  dashboardTemplatesRoute,
  dataSourcesRoute,
  experimentsRoute,
  fastingLogRoute,
  glucoseLogRoute,
  groupsRoute,
  helpRoute,
  homeRoute,
  hydrationLogRoute,
  kpisRoute,
  labResultsRoute,
  lifeEventsRoute,
  manualRecordEntryRoute,
  metricsRoute,
  myMetricsRoute,
  mySupplementsRoute,
  nutritionLogRoute,
  shopOrdersPageDynamicSignature,
  profileRoute,
  protectedPathsWithoutLinks,
  supplementLogRoute,
  targetsRoute,
  unprotectedRoutes,
  vaultRoute,
  wellnessLogRoute,
  sandbox2Route,
  unprotectedPatterns,
  shopSupplementsRoute,
  shopRoute,
  shopOrdersRoute,
  adminSandboxRoute,
  adminOrderManagementRoute,
  adminDevToolsRoute,
  shopLabsPackagesRoute,
  shopCalculatorsRoute,
  adminComponentLibraryRoute,
} from '../../../common/constants/routes'
import { enum_membership_tier_enum } from '../../../generated/graphql'
import { useDevTools } from '../../../hooks/useDevTools'
import { typedFalsyFilter } from '../../../typescript/guards/typedFalsyFilter'
import { featureFlags } from '../../auth/FeatureFlagProvider'
import { useAccount } from '../../auth/hooks/useAccount'
import { useFeatureFlag } from '../../auth/hooks/useFeatureFlag'
import type { RealizeUser } from '../../auth/RealizeUser'
import { useLogInState } from '../../auth/recoil'
import { isExploreQueryParamName } from '../../circles/common'
import { makeArray } from '../../../utilities/makeArray'
import { typeDynamicRouteSegment } from '../../../common/constants/dynamicRouteSegments'
import { DEPRECATED_LABS_ONLY_TIER, FREE_MEMBER_TIERS } from '../../account/utils/membership-tiers'
import { useRecommendationsLinkAccountingForLastVisitedScreen } from '../../recommendations/hooks/useRecommendationsLinkAccountingForLastVisitedScreen'
import { useRouter } from 'next/navigation'
import { useRoutePattern } from '../../../utilities/useRoutePattern'
import { useIsCanada } from '../../account/features/franchise-membership/hooks/useHost/useIsCanada'
import { useRedirectCanadianUsersAwayFromLabPages } from './useRedirectCanadianUsersAwayFromLabPages'

type Params = {
  isLifeEventsEnabled: boolean
  isUserGroupsEnabled: boolean
  isAuthenticated: boolean
  user?: RealizeUser
  isMetricsExplorerEnabled: boolean
  isOrderHistoryEnabled: boolean
  membershipTier?: enum_membership_tier_enum | null
  isActionPlanEnabled: boolean
  isActionPlanReportEnabled: boolean
  isActionPlanCirclesEnabled: boolean
  isKpisEnabled: boolean
  isLabResultsV2Enabled: boolean
  recommendationsUrl: string
  isCanada: boolean
}

export const profileSubnavLinkId = 'profile-subnav-link'
export const accountNavLinkId = 'account-nav-link'

const getUrls = (links: NavLink[]) => {
  const urls = new Set<string>()
  JSON.parse(JSON.stringify(links), (key, value) => {
    if (key === 'url') urls.add(value)
  })
  return urls
}

const adminRoles = [Role.admin, Role.realizeEmployee, Role.orderManagement]

const makeNavLinks = ({
  isLifeEventsEnabled,
  user,
  isUserGroupsEnabled,
  isMetricsExplorerEnabled,
  isAuthenticated,
  membershipTier,
  isActionPlanEnabled,
  isActionPlanReportEnabled,
  isActionPlanCirclesEnabled,
  isKpisEnabled,
  isLabResultsV2Enabled,
  recommendationsUrl,
  isCanada,
}: Params) => {
  const allNavLinks: NavLink[] = [
    { title: 'Home', url: homeRoute, isVisibleForNewLaunchUsers: true },
    // TODO: add link ids
    isActionPlanEnabled && {
      title: 'Action Plan',
      isVisibleForNewLaunchUsers: false,
      subnav: [
        ...(isActionPlanReportEnabled
          ? [
              {
                title: 'Reports',
                url: actionPlanReportsRoute,
                roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
              },
            ]
          : []),
        {
          title: 'My Plan',
          url: actionPlanRoute,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        ...(isActionPlanCirclesEnabled
          ? [
              {
                title: 'Circles',
                url: actionPlanCirclesRoute,
                roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
              },
            ]
          : []),
        ...(isActionPlanCirclesEnabled
          ? [
              {
                title: 'Explore Circles',
                url: `${actionPlanCirclesRoute}?${isExploreQueryParamName}=true`,
                roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
                hideLinkOnNavs: true,
              },
            ]
          : []),
        ...(isActionPlanCirclesEnabled ? [{ url: actionPlanCirclesPageDynamicSignature, hideLinkOnNavs: true }] : []),
      ],
      roles: [Role.user],
      roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
    },
    {
      title: 'Logging',
      isVisibleForNewLaunchUsers: false,
      subnav: [
        {
          title: 'Hydration',
          url: hydrationLogRoute,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        {
          title: 'Nutrition',
          url: nutritionLogRoute,
        },
        {
          title: 'Wellness',
          url: wellnessLogRoute,
        },
        {
          title: 'Supplements',
          url: supplementLogRoute,
        },
        {
          title: 'Fasting',
          url: fastingLogRoute,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        {
          title: 'Glucose',
          url: glucoseLogRoute,
        },
        {
          title: 'Manual Entry',
          url: manualRecordEntryRoute,
        },
      ],
      roles: [Role.user],
      roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
    },
    {
      title: 'Shop',
      isVisibleForNewLaunchUsers: true,
      hideLinkOnNavs: true,
      TEMP_ignoreHideOnNavLinksWhenShowingOnSidebar: true,
      subnav: [
        {
          url: shopRoute,
          hideLinkOnSidebar: true,
          hideLinkOnNavs: true,
          isVisibleForNewLaunchUsers: true,
          isViewableAnonymously: true,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        ...(isCanada
          ? []
          : [
              {
                title: 'Lab Tests',
                url: shopLabsPackagesRoute,
                isVisibleForNewLaunchUsers: true,
                isViewableAnonymously: true,
                roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
              },
            ]),
        {
          title: 'Supplements',
          url: shopSupplementsRoute,
          isVisibleForNewLaunchUsers: true,
          isViewableAnonymously: true,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        // {
        //   title: 'Medications',
        //   url: shopMedicationsRoute,
        //   isViewableAnonymously: true,
        //   roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        // },
        {
          title: 'Calculators',
          url: shopCalculatorsRoute,
          isVisibleForNewLaunchUsers: true,
          isViewableAnonymously: true,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
          additionalActiveRouteMatches: [`${shopCalculatorsRoute}/[${typeDynamicRouteSegment}]`],
        },
        {
          title: 'Order History',
          isVisibleForNewLaunchUsers: true,
          url: shopOrdersRoute,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
          roles: [Role.user],
        },
      ],
      roles: [Role.user],
      isViewableAnonymously: true,
      roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
    },
    {
      title: 'My Data',
      subnav: [
        {
          title: 'Recommendations',
          url: recommendationsUrl,
          isViewableAnonymously: true,
          isVisibleForNewLaunchUsers: true,
        },
        { title: 'Dashboards', url: dashboardsRoute, roles: [Role.realizeEmployee], isVisibleForNewLaunchUsers: true },
        ...(isKpisEnabled
          ? [
              {
                title: 'KPIs',
                url: kpisRoute,
                isVisibleForNewLaunchUsers: true,
                roleRestrictionExemptMembershipTiers: [
                  enum_membership_tier_enum.ACTION_PLAN_ONLY,
                  enum_membership_tier_enum.FREE_MEMBER,
                ],
              },
            ]
          : []),
        ...(isLabResultsV2Enabled && !isCanada
          ? [
              {
                title: 'Lab Results',
                isVisibleForNewLaunchUsers: true,
                url: labResultsRoute,
                roleRestrictionExemptMembershipTiers: [
                  enum_membership_tier_enum.LABS_ONLY,
                  enum_membership_tier_enum.ACTION_PLAN_ONLY,
                  enum_membership_tier_enum.FREE_MEMBER,
                ],
              },
            ]
          : []),
        ...(isMetricsExplorerEnabled ? [{ title: 'Metrics', url: metricsRoute }] : []),
        {
          title: 'Experiments',
          url: experimentsRoute,
          roles: [Role.realizeEmployee],
          isVisibleForNewLaunchUsers: true,
        },
        {
          title: 'Dashboard Templates',
          url: dashboardTemplatesRoute,
          roles: [Role.realizeEmployee],
          isVisibleForNewLaunchUsers: true,
        },
      ],
      roles: [Role.user],
      isViewableAnonymously: true,
      isVisibleForNewLaunchUsers: true,
    },
    ...(isCanada
      ? []
      : [
          {
            title: 'Labs',
            isVisibleForNewLaunchUsers: false,
            subnav: [
              {
                title: 'Results',
                url: labResultsRoute,
                roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
                exclusiveMembershipWhitelist: FREE_MEMBER_TIERS,
              },
            ],
            roles: [Role.user],
            roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
            exclusiveMembershipWhitelist: FREE_MEMBER_TIERS,
          },
        ]),
    {
      title: 'Account',
      id: accountNavLinkId,
      isVisibleForNewLaunchUsers: true,
      subnav: [
        {
          title: 'Profile',
          url: profileRoute,
          isVisibleForNewLaunchUsers: true,
          id: profileSubnavLinkId,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        { title: 'My Metrics', url: myMetricsRoute },
        { title: 'Targets', url: targetsRoute },
        {
          title: 'Supplement Stack',
          url: mySupplementsRoute,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        {
          title: 'Data Sources',
          url: dataSourcesRoute,
          isVisibleForNewLaunchUsers: true,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        ...(isLifeEventsEnabled ? [{ title: 'Life Events', url: lifeEventsRoute }] : []),
        {
          title: 'Vault',
          url: vaultRoute,
          roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
        },
        ...(isUserGroupsEnabled ? [{ title: 'Groups', url: groupsRoute }] : []),
      ],
      roles: [Role.user],
      roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
    },
    {
      title: 'Help',
      url: helpRoute,
      roleRestrictionExemptMembershipTiers: FREE_MEMBER_TIERS,
      isVisibleForNewLaunchUsers: true,
    },
    {
      title: 'Admin',
      isOpenByDefault: true,
      isVisibleForNewLaunchUsers: true,
      subnav: [
        {
          title: 'Order Management',
          url: adminOrderManagementRoute,
          roles: [Role.orderManagement, Role.admin, Role.realizeEmployee],
          isVisibleForNewLaunchUsers: true,
        },
        { title: 'Dev Tools', url: adminDevToolsRoute, roles: [Role.admin], isVisibleForNewLaunchUsers: true },
        {
          title: 'Sandbox',
          url: adminSandboxRoute,
          roles: [Role.admin],
          isVisibleForNewLaunchUsers: true,
        },
        {
          title: 'Component Library',
          url: adminComponentLibraryRoute,
          roles: [Role.admin],
          isVisibleForNewLaunchUsers: true,
        },
        {
          title: 'Sandbox2',
          url: sandbox2Route,
          roles: [Role.admin],
          hideLinkOnNavs: true,
        },
      ],
      roles: adminRoles,
    },
  ].filter(typedFalsyFilter)

  const roleFilter = (item: LinkWithoutSubnav | NavLink) =>
    (item.isViewableAnonymously && user?.hasRole(Role.anonymous)) ||
    !item.roles?.length ||
    user?.getMatchingRole(item.roles)

  const roleAndTierFilter = (x: NavLink | LinkWithoutSubnav) => {
    return roleFilter(x) || (x.isViewableAnonymously && user?.hasRole(Role.anonymous))
  }

  function exclusiveMembershipWhitelistFilter(x: NavLink | LinkWithoutSubnav) {
    if (!x.exclusiveMembershipWhitelist || x.exclusiveMembershipWhitelist.length === 0) return true
    const whitelist = makeArray(x.exclusiveMembershipWhitelist)
    return membershipTier && whitelist.includes(membershipTier)
  }

  const newLaunchUserFilter = (x: NavLink | LinkWithoutSubnav) => {
    if (!membershipTier) return true
    if (![enum_membership_tier_enum.FREE_MEMBER, enum_membership_tier_enum.PAID_MEMBER].includes(membershipTier)) {
      return true
    }

    return x.isVisibleForNewLaunchUsers
  }

  // !!ALWAYS OVERRIDE ANY LOGIC ABOVE WITH THIS FILTER!! NO MATTER WHAT, DO NOT ALLOW NON ADMINS TO SEE ADMIN CONTENT
  const adminRoleFilter = (item: NavLink | LinkWithoutSubnav) => {
    if (!adminRoles.some((x) => item.roles?.includes(x))) return true
    return !item.roles?.length || user?.getMatchingRole(item.roles)
  }

  const visibleNavLinks = allNavLinks
    .filter(typedFalsyFilter)
    .filter(roleAndTierFilter)
    .filter(exclusiveMembershipWhitelistFilter)
    .filter(newLaunchUserFilter)
    .filter(adminRoleFilter)
    .map((item) => {
      if (isNavHeader(item)) {
        return {
          ...item,
          subnav: item.subnav
            .filter(roleAndTierFilter)
            .filter(exclusiveMembershipWhitelistFilter)
            .filter(newLaunchUserFilter)
            .filter(adminRoleFilter),
        }
      }

      return item
    })

  const visibleNavLinkUrls = getUrls(visibleNavLinks)
  // TODO these should also get filtered based on role and all the other criteria we have
  const authorizedDynamicPathSignatures = isAuthenticated
    ? [shopOrdersPageDynamicSignature, actionPlanCirclesPageDynamicSignature]
    : []
  const hiddenAllowedPaths = isAuthenticated ? protectedPathsWithoutLinks : []
  const authorizedPaths = new Set([
    ...visibleNavLinkUrls,
    ...unprotectedRoutes,
    ...authorizedDynamicPathSignatures,
    ...hiddenAllowedPaths,
  ])
  if (membershipTier === DEPRECATED_LABS_ONLY_TIER) {
    authorizedPaths.delete(homeRoute)
  }
  return { visibleNavLinks, visibleNavLinkUrls, authorizedPaths, authorizedDynamicPathSignatures }
}

export type LinkWithoutSubnav = Omit<NavLinkBase, 'title'> & {
  title?: string
  url: string
  target?: string
}

// TODO should rewrite all of this logic. way too complex and there is a ton of overlap in terms of
// whitelists/blaclists/automatic exemptions/exemption overrides. also, should split out the logic more discretely
// betwee nav, subnav, and sidebar as they are not very well aligned and thus should not all start from a baseline of
// the sidebar items
type NavLinkBase = {
  title: string
  id?: string
  roles?: Role[]
  roleRestrictionExemptMembershipTiers?: enum_membership_tier_enum[] | enum_membership_tier_enum
  // ?? I noticed that not giving a title property to the LinkWithoutSubnav object is enough to hide links on both the Subnav
  //    and the Sidebar components, but I believe we must be explicit with this behavior.
  hideLinkOnNavs?: boolean
  hideLinkOnSidebar?: boolean
  isViewableAnonymously?: boolean
  // * when this is present, further filter a link by removing any tier not included in the whitelist
  exclusiveMembershipWhitelist?: enum_membership_tier_enum[] | enum_membership_tier_enum
  additionalActiveRouteMatches?: string[]
  isVisibleForNewLaunchUsers?: boolean
  TEMP_ignoreHideOnNavLinksWhenShowingOnSidebar?: boolean
}
type NavHeader = NavLinkBase & { subnav: LinkWithoutSubnav[]; isOpenByDefault?: boolean }
type NavHeaderLink = NavLinkBase & { url: string }

export type NavLink = NavHeader | NavHeaderLink

const isNavHeader = (link: NavLink): link is NavHeader => 'subnav' in link

export function useNavLinks(): { subnav: LinkWithoutSubnav[]; navLinks: NavLink[] } {
  useRedirectCanadianUsersAwayFromLabPages()
  const routePattern = useRoutePattern() ?? ''
  const replace = useRouter().replace
  const { user, isLoading, isAuthenticated } = useAccount()

  const membershipTier = useAccount().user?.membershipTier
  const isOrderHistoryEnabled = useFeatureFlag(featureFlags.orderHistory).isEnabled
  const isLifeEventsEnabled = useFeatureFlag(featureFlags.lifeEvents).isEnabled
  const isUserGroupsEnabled = useFeatureFlag(featureFlags.userGroups).isEnabled
  const isMetricsExplorerEnabled = useFeatureFlag(featureFlags.metricsExplorer).isEnabled
  const isActionPlanEnabled = useFeatureFlag(featureFlags.actionPlans).isEnabled
  const isActionPlanReportEnabled = useFeatureFlag(featureFlags.actionPlansReports).isEnabled
  const isActionPlanCirclesEnabled = useFeatureFlag(featureFlags.actionPlansCircles).isEnabled
  const isLabResultsV2Enabled = useFeatureFlag(featureFlags.labResultsV2).isEnabled
  const isKpisEnabled = useFeatureFlag(featureFlags.kpis).isEnabled
  const { featureFlagsReady } = useFeatureFlag(featureFlags.metricsExplorer)

  const recommendationsUrl = useRecommendationsLinkAccountingForLastVisitedScreen()

  const isCanada = useIsCanada()

  const { authorizedPaths, visibleNavLinks, visibleNavLinkUrls } = React.useMemo(
    () =>
      makeNavLinks({
        isLifeEventsEnabled,
        user,
        isAuthenticated,
        isUserGroupsEnabled,
        isMetricsExplorerEnabled,
        isOrderHistoryEnabled,
        membershipTier,
        isActionPlanEnabled,
        isActionPlanReportEnabled,
        isActionPlanCirclesEnabled,
        isKpisEnabled,
        isLabResultsV2Enabled,
        recommendationsUrl,
        isCanada,
      }),
    [
      isLifeEventsEnabled,
      isAuthenticated,
      user,
      isUserGroupsEnabled,
      isMetricsExplorerEnabled,
      isOrderHistoryEnabled,
      membershipTier,
      isActionPlanEnabled,
      isActionPlanReportEnabled,
      isActionPlanCirclesEnabled,
      isKpisEnabled,
      isLabResultsV2Enabled,
      recommendationsUrl,
      isCanada,
    ]
  )

  const [{ airplaneMode }] = useDevTools()

  const [{ postAuthRedirectInProgress }, setLogInState] = useLogInState()

  React.useEffect(() => {
    if (isLoading || !featureFlagsReady || airplaneMode || postAuthRedirectInProgress) return
    if (!authorizedPaths.has(routePattern) && !unprotectedPatterns.some((x) => x.test(routePattern))) {
      if (!isAuthenticated) {
        setLogInState((s) => ({ ...s, isLoggingIn: true }))
      } else {
        const [firstRoute = homeRoute] = authorizedPaths
        replace(firstRoute)
      }
    }
  }, [
    featureFlagsReady,
    isLoading,
    airplaneMode,
    routePattern,
    authorizedPaths,
    visibleNavLinkUrls,
    replace,
    isAuthenticated,
    setLogInState,
    postAuthRedirectInProgress,
  ])

  const subnav = React.useMemo(() => {
    // ts inference struggling here
    const match = visibleNavLinks.find(
      (link) =>
        link &&
        'subnav' in link &&
        Array.isArray(link.subnav) &&
        (link.subnav.some((subnavLink) => subnavLink.url.split('?')[0] === routePattern) ||
          link.subnav.some((subnavLink) => subnavLink.additionalActiveRouteMatches?.includes(routePattern)))
    )
    if (match?.hideLinkOnNavs) return []
    return (match && 'subnav' in match && match.subnav) || []
  }, [routePattern, visibleNavLinks])

  return { navLinks: visibleNavLinks, subnav }
}
