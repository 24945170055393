import * as React from 'react'
import { recommendationsRoute } from '../../../common/constants/routes'
import { activeOnboardingStepUrlSessionStorageKey } from '../../../common/constants/sessionStorage'
import { useAutoUpdatingRef } from '../../../hooks/useAutoUpdatingRef'
import { useUrl } from '../../../hooks/useUrl'

export function useRecommendationsLinkAccountingForLastVisitedScreen() {
  const [recLink, setRecLink] = React.useState(recommendationsRoute)

  const recLinkRef = useAutoUpdatingRef(recLink)

  const url = useUrl()
  React.useEffect(() => {
    const lastVisitedScreen = sessionStorage.getItem(activeOnboardingStepUrlSessionStorageKey)
    if (lastVisitedScreen && lastVisitedScreen !== recLinkRef.current) {
      setRecLink(lastVisitedScreen)
    }
  }, [url, recLinkRef])

  return recLink
}
