'use client'
import React from 'react'
import throttle from 'lodash/throttle'

type TScrollingDirection = { down: boolean; up: boolean }

/**
 * Hook which return whether the user is scrolling up, down or not at all
 */
export const useScrollingDirection = (input?: any): TScrollingDirection => {
  const [scrollingDirection, setScrollingDirection] = React.useState<TScrollingDirection>({
    down: false,
    up: false,
  })

  const scrollTopRef = React.useRef<number | undefined>()

  React.useEffect(() => {
    // need to account for elements and the window object, so use both pageYOffset and scrollTop
    let timeout: NodeJS.Timeout
    let unmounted = false
    const listener = () => {
      const newScrollTop = window.scrollY
      setScrollingDirection({
        down: newScrollTop > (scrollTopRef.current || 0),
        up: newScrollTop < (scrollTopRef.current || 0),
      })
      scrollTopRef.current = newScrollTop
      timeout = setTimeout(() => {
        if (scrollTopRef.current === newScrollTop && !unmounted) {
          setScrollingDirection({ down: false, up: false })
        }
      }, 150)
    }

    const throttled = throttle(listener, 100)
    window.addEventListener('scroll', throttled)

    return () => {
      if (timeout) clearTimeout(timeout)
      unmounted = true
      window.removeEventListener('scroll', throttled)
    }
  }, [input])

  return scrollingDirection
}
