import React from 'react'
import AnimateHeight, { AnimateHeightProps, Height } from 'react-animate-height'
import { isRealNumber } from '../../typescript/guards/isRealNumber'

type TProps = {
  isOpen: boolean
  children: React.ReactNode
  closedHeight?: Height
} & Partial<AnimateHeightProps>

function RefForwardedCollapse(
  {
    children,
    isOpen,
    duration = 300,
    easing = 'ease-in-out',
    delay = 0,
    height,
    closedHeight = 0,
    className,
    ...rest
  }: TProps,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  const [openAdjustedHeight, setOpenAdjustedHeight] = React.useState<Height>(isOpen ? 'auto' : closedHeight)

  React.useEffect(() => {
    setOpenAdjustedHeight(!isOpen ? closedHeight : isRealNumber(height) ? height : 'auto')
  }, [isOpen, height, closedHeight])

  return (
    <AnimateHeight
      duration={duration}
      easing={easing}
      delay={delay}
      className={className}
      height={openAdjustedHeight}
      ref={ref}
      {...rest}
    >
      {children}
    </AnimateHeight>
  )
}

export const Collapse = React.forwardRef(RefForwardedCollapse)
